
import { defineComponent, ref } from 'vue'
import ForgetPasswordForm from '@/components/particles/forms/ForgetPasswordForm/ForgetPasswordForm.vue'

export default defineComponent({
  name: 'ForgetPasswordView',
  components: { ForgetPasswordForm },
  setup() {
    const currentComponent = ref('ForgetPasswordEmailCheck')
    return {
      currentComponent,
    }
  },
})
