
import { defineComponent, defineAsyncComponent } from 'vue'
import Logo from '@/components/particles/common/Logo.vue'

export default defineComponent({
  components: {
    ForgetPasswordEmailCheck: defineAsyncComponent(
      () => import('./ForgetPasswordFormEmailCheck.vue')
    ),
    ForgetPasswordSmsCheck: defineAsyncComponent(
      () => import('./ForgetPasswordFormSmsCheck.vue')
    ),
    ForgetPasswordSmsCodeSent: defineAsyncComponent(
      () => import('./ForgetPasswordFormSmsCodeSent.vue')
    ),
    ForgetPasswordNewPassword: defineAsyncComponent(
      () => import('./ForgetPasswordFormNewPassword.vue')
    ),
    Logo,
  },
  props: {
    currentComponent: {
      type: String,
      required: true,
      default: '',
    },
  },

  setup() {
    return {}
  },
})
